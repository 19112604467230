var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Data Referensi ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"employee_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NIP","label-for":"employee_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"employee_id","reduce":function (employee_nip) { return employee_nip.id; },"placeholder":"Pilih Pegawai","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listPegawai,"label":"name"},model:{value:(_vm.dataParent.employee_id),callback:function ($$v) {_vm.$set(_vm.dataParent, "employee_id", $$v)},expression:"dataParent.employee_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tanggal Efektif","label-for":"effective_date"}},[_c('validation-provider',{attrs:{"name":"effective_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"effective_date","type":"date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Efektif"},model:{value:(_vm.dataParent.effective_date),callback:function ($$v) {_vm.$set(_vm.dataParent, "effective_date", $$v)},expression:"dataParent.effective_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Kelompok gaji","label-for":"group"}},[_c('validation-provider',{attrs:{"name":"group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"group","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Kelompok gaji"},model:{value:(_vm.dataParent.group),callback:function ($$v) {_vm.$set(_vm.dataParent, "group", $$v)},expression:"dataParent.group"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Bank","label-for":"bank_name"}},[_c('validation-provider',{attrs:{"name":"bank_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bank_name","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Nama Bank"},model:{value:(_vm.dataParent.bank_name),callback:function ($$v) {_vm.$set(_vm.dataParent, "bank_name", $$v)},expression:"dataParent.bank_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"No. Rekening","label-for":"bank_account_number"}},[_c('validation-provider',{attrs:{"name":"bank_account_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bank_account_number","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Nominal"},model:{value:(_vm.dataParent.bank_account_number),callback:function ($$v) {_vm.$set(_vm.dataParent, "bank_account_number", $$v)},expression:"dataParent.bank_account_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Pemilik Rekening","label-for":"bank_account_name"}},[_c('validation-provider',{attrs:{"name":"bank_account_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bank_account_name","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Deskripsi"},model:{value:(_vm.dataParent.bank_account_name),callback:function ($$v) {_vm.$set(_vm.dataParent, "bank_account_name", $$v)},expression:"dataParent.bank_account_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"tax_status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status Pajak","label-for":"tax_status","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"tax_status","reduce":function (tax_status) { return tax_status.value; },"placeholder":"Pilih Aktif / Tidak Aktif","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listTaxStatus,"label":"tax_status"},model:{value:(_vm.dataParent.tax_status),callback:function ($$v) {_vm.$set(_vm.dataParent, "tax_status", $$v)},expression:"dataParent.tax_status"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',{attrs:{"offset-md":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.formSubmitted}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Back ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }