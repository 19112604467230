<template>
  <b-card>
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form class="mt-1" @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Data Referensi
              </h5>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="employee_id" rules="required">
                <b-form-group label="NIP" label-for="employee_id" :state="errors.length > 0 ? false : null">
                  <v-select id="employee_id" v-model="dataParent.employee_id"
                    :reduce="(employee_nip) => employee_nip.id" placeholder="Pilih Pegawai"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listPegawai" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Tanggal Efektif" label-for="effective_date">
                <validation-provider #default="{ errors }" name="effective_date" rules="required">
                  <b-form-input id="effective_date" v-model="dataParent.effective_date" type="date"
                    :state="errors.length > 0 ? false : null" placeholder="Tanggal Efektif" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Kelompok gaji" label-for="group">
                <validation-provider #default="{ errors }" name="group" rules="required">
                  <b-form-input id="group" v-model="dataParent.group" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Kelompok gaji" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nama Bank" label-for="bank_name">
                <validation-provider #default="{ errors }" name="bank_name" rules="required">
                  <b-form-input id="bank_name" v-model="dataParent.bank_name" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Nama Bank" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col md="6">

              <b-form-group label="No. Rekening" label-for="bank_account_number">
                <validation-provider #default="{ errors }" name="bank_account_number" rules="required">
                  <b-form-input id="bank_account_number" v-model="dataParent.bank_account_number" type="number"
                    :state="errors.length > 0 ? false : null" placeholder="Nominal" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Nama Pemilik Rekening" label-for="bank_account_name">
                <validation-provider #default="{ errors }" name="bank_account_name" rules="required">
                  <b-form-input id="bank_account_name" v-model="dataParent.bank_account_name" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Deskripsi" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider #default="{ errors }" name="tax_status" rules="required">
                <b-form-group label="Status Pajak" label-for="tax_status" :state="errors.length > 0 ? false : null">
                  <v-select id="tax_status" v-model="dataParent.tax_status" :reduce="(tax_status) => tax_status.value"
                    placeholder="Pilih Aktif / Tidak Aktif" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listTaxStatus" label="tax_status" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                @click="() => $router.go(-1)">
                Back
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      listPegawai: [],
      listTaxStatus: [],
      required,
      dataParent: {
        employee_id: '',
        effective_date: '',
        group: '',
        bank_name: '',
        bank_account_number: '',
        bank_account_name: '',
        npwp_number: '',
        tax_status: '',
      },
      dataIsBPJSTK: [
        { value: 1, text: 'Ada' },
        { value: 0, text: 'Tidak Ada' },
      ],
      dataIsBPJSKes: [
        { value: 1, text: 'Ada' },
        { value: 0, text: 'Tidak Ada' },
      ],
      dataIsBPJSPensiun: [
        { value: 1, text: 'Ada' },
        { value: 0, text: 'Tidak Ada' },
      ],

      dataIsActive: [
        { value: 'TK0', text: 'Tidak Kawin' },
        { value: 0, text: 'Tidak Aktif' },
      ],
    }
  },
  computed: {

  },
  created() {
    this.getpegawai()
    this.gettaxstatus()
  },
  methods: {
    async getpegawai() {
      try {
        const response = await this.$http.get('/employees')
        this.listPegawai = response.data.data
        console.log(response.data.data);
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async gettaxstatus() {
      try {
        const response = await this.$http.get('/taxstatuses')
        this.listTaxStatus = response.data.data
        console.log(response.data.data);
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        await this.$http.post('/salaryreferences', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-referensi' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
